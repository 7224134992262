.title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /*@media only screen and (orientation: portrait) {
        flex-wrap: wrap;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        flex-wrap: wrap;
    }*/

    .title {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
            max-height: 18px;
        }

        p {
            margin: 0;
            font-size: 1vw;
            //font-weight: 500;
        }
    }

    .actions {
        display: flex;
        align-items: center;

        /*@media only screen and (orientation: portrait) {
            width: 100%;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            width: 100%;
        }*/

        button {
            padding: 0;
            background: transparent;
            border: none;
            outline: none;

            &:hover {
                background: transparent;
            }
        }
    }
}
.mapClass {
    min-height: 500px;

    @media only screen and (orientation: portrait) {
        min-height: 40vh;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        min-height: 80vh;
    }
}
.bookmark {
    button.bookmark-tag {
        padding: 0;
        min-width: auto;
        position: absolute;
        top: 5px;
        left: 5px;

        img {
            width: 14px;
        }
    }
}

