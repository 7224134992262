.stages-timeline-container{
    display: table;
    padding: 0px 80px;
    width: 50%;
}

.timeline-input {
    padding: 5px 15px;
    width: 450px;
}
.actions-class{
    padding: 30px 14px;
}
.new-column{
    float: left;
}
.stage-header{
    font-size: 20px; 
    font-weight: 300; 
    color: #4e4e4d;
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: capitalize;
}
hr {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    border-style: solid;
    border: 0px;
    border-bottom: 0.5px solid grey;
}