.customer-dashboard-root {
    @media only screen and (orientation: landscape) and (max-width: 992px) {
        z-index: 101;
    }

    @media only screen and (orientation: portrait) {
        z-index: 101;
    }
}
.googleMapDimentions {
    height: 33vh;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        height: 33vh;
        padding: 1vw;
    }

    @media only screen and (orientation: portrait) {
        height: 33vh;
        padding: 6vw;
    }
}
.dashboard-content {
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .user-title {
        font-size: 17px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .flex-container {
        display: flex;
        justify-content: space-between;

        & > div {
            background-color: #f2f2f2;
            padding: 16px;
            position: relative;
        }

        .timezone-box {
            width: calc(60% - 12px);
            padding: 32px 24px 12px;
            display: flex;
            justify-content: space-between;

            .timezone-edit {
                position: absolute;
                padding: 0;
                background: transparent;
                border: none;
                outline: none;
                right: 24px;
                line-height: normal;
                font-weight: normal;
                top: 8px;
                padding: 0;
                font-size: 16px;
                text-decoration: underline;

                img + span {
                    margin-left: 5px;
                }
            }

            .timezone {
                background: #fff;
                border: 1px solid #797979;
                width: calc(33.33% - 16px);
                padding: 6px 12px 8px;
                height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .tz-title {
                    margin-left: -6px;
                    font-size: 16px;
                }

                .tz-meridiem {
                    font-size: 16px;
                }

                .tz-weather {
                    font-size: 15px;
                }

                .tz-time {
                    font-size: 26px;
                    font-weight: 500;
                    padding: 0 5px;
                }

                .tz-temp {
                    font-size: 26px;
                    font-weight: 500;
                    padding-right: 12px;
                    position: relative;

                    img {
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }

        .insight-box {
            width: calc(40% - 12px);

            .insight-row {
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                line-height: 21px;
                margin-top: 16px;
            }

            .read-more {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
.active-shipments {
    margin-top: 24px;
    margin-bottom: 24px;
    //background-color: #fbfbfb;
    //padding: 24px;
    position: relative;

    @media only screen and (orientation: portrait) {
        padding: 0;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        padding: 0;
    }
}
button.icon-btn {
    display: inline-flex;
    align-items: center;
    color: #000;
    line-height: normal;
    font-weight: normal;
    padding: 0;
    span { 
        text-transform: capitalize; 
        font-size: 16px;
        color: #000;
    }
    img {
        max-height: 1.5vw;
    }
    img + span {
        margin-left: 0.5vw;
    }
    &.underline {
        text-decoration: underline;
    }
    & + .icon-btn {
        margin-left: 1vw;
    }
}
button.icon-btn-header {
    font-size: 0.8vw;
    width: 4vw;
    height: 1.68vw;
    background: #00AEEF;
    box-shadow: 0px 2px 6px #00000029;
    border: 0.5px solid #FFFFFF;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: #fff;
    line-height: normal;
    font-weight: normal;
    padding: 0;
    margin: 4px;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        background: transparent;
        box-shadow: none;
        border: none;
        min-width: 0;
        width: 3vw;
    }

    @media only screen and (orientation: portrait) {
        background: transparent;
        box-shadow: none;
        border: none;
        width: 3vw;
        min-width: 0;
    }

    span {
        text-transform: capitalize;
        font-size: 0.8vw;
        color: #fff;
    }

    img {
        max-height: 1vw;

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            height: 2.5vw;
            max-height: 4vw;
        }

        @media only screen and (orientation: portrait) {
            height: 4vw;
            max-height: 4vw;
        }
    }

    img + span {
        margin-left: 0.5vw;
    }

    &.underline {
        text-decoration: underline;
    }

    & + .icon-btn {
        margin-left: 1vw;
    }
}
button.icon-btn-header:hover {
    font-size: 0.8vw;
    width: 4vw;
    height: 1.68vw;
    background: #00AEEF;
    box-shadow: 0px 2px 6px #00000029;
    border: 0.5px solid #FFFFFF;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: #fff;
    line-height: normal;
    font-weight: normal;
    padding: 0;
    margin: 4px;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        background: transparent;
        box-shadow: none;
        border: none;
        min-width: 0;
        width: 3vw;
    }

    @media only screen and (orientation: portrait) {
        background: transparent;
        box-shadow: none;
        border: none;
        width: 3vw;
        min-width: 0;
    }

    span {
        text-transform: capitalize;
        font-size: 0.8vw;
        color: #fff;
    }

    img {
        max-height: 1vw;

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            height: 4vw;
            max-height: 4vw;
        }

        @media only screen and (orientation: portrait) {
            height: 4vw;
            max-height: 4vw;
        }
    }

    img + span {
        margin-left: 0.5vw;
    }

    &.underline {
        text-decoration: underline;
    }

    & + .icon-btn {
        margin-left: 1vw;
    }
}
button.icon-btn-header-cust {
    font-size: 0.8vw;
    //width: 4vw;
    height: 1.68vw;
    background: #fff;
    box-shadow: 0px 2px 6px #00000029;
    border: 0.5px solid #FFFFFF;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: #000;
    line-height: normal;
    font-weight: normal;
    padding: 0;
    margin: 4px;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        background: transparent;
        box-shadow: none;
        border: none;
        min-width: 0;
        width: 3vw;
    }

    @media only screen and (orientation: portrait) {
        background: transparent;
        box-shadow: none;
        border: none;
        min-width: 0;
        width: 3vw;
    }

    span {
        text-transform: capitalize;
        font-size: 0.8vw;
        color: #000;
    }

    img {
        max-height: 1vw;

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            max-height: 4vw;
            height: 4vw;
        }

        @media only screen and (orientation: portrait) {
            max-height: 4vw;
            height: 4vw;
        }
    }

    img + span {
        margin-left: 0.5vw;
    }

    &.underline {
        text-decoration: underline;
    }

    & + .icon-btn {
        margin-left: 1vw;
    }
}