.customer-container {
    //margin-left: -24px;
    //margin-right: -24px;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        margin: 0;
    }

    @media only screen and (orientation: portrait) {
        margin: 0;
    }

    .customer-content {
        padding-left: 15.9vw;

        @media only screen and (orientation: portrait) {
            background-image: none !important;
            padding-left: 0;
            padding-top: 0;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            background-image: none !important;
            padding-left: 0;
            padding-top: 0;
        }
    }
}
button.MuiButton-text {
    color: #333;
    text-transform: capitalize;
}