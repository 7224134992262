
.abc{}
.content-box {
    padding: 5px 8px;

    .title-bold {
        color: #555;
        font-size: 14px;
        font-weight: 600;
    }

    .title-lg {
        font-size: 18px;
        font-weight: bold;
        align-items: center;
        display: flex;
    }

    .title-sm {
        font-size: 12px;
        font-weight: normal;
        margin-left: 5px;
    }

    .tb-row {
        font-size: 12px;
        line-height: 1.5;
        margin-top: 4px;

        strong {
            font-size: 14px;
        }
    }
}
.search-input {
    input {
        padding: 4px 10px !important;
        font-size: 14px !important;
        line-height: 1 !important;
    }
}
.search-input-1 {
    input {
        padding: 7px 8px !important;
        font-size: 14px !important;
    }
}
.parcel-table {
    td {
        padding-top: 0 !important;
    }
}
.botton-dialog {
    position: fixed;
    background: #fff;
    height: 50px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    border-top: 1px solid #7f7f7f;
    animation: bottomPasition .3s linear;

    @keyframes bottomPasition {
        from {
            bottom: -140px;
        }

        to {
            bottom: 0;
        }
    }

    .total-parcels {
        background-color: #d7d7d7;
        height: 100%;
        min-width: 185px;
        float: right;
    }
}
.border-box.light-border {
  border: 1px solid #c9c9c9;
}

.parcel-table {
    .checkbox {
        padding: 0;
    }

    th {
        //background-color: #f2f2f2;
        padding: 5px 10px;
        white-space: nowrap;
        border-bottom: 0;
        font-weight: bold;
        font-size: 14px;
    }

    td {
        padding: 8px 6px;
        font-size: 11px;
        //white-space: nowrap;
        //background-color: #fff;
        //border-bottom: 8px solid #f2f2f2;
        position: relative;
        max-width: 8vw;

        &:not(:nth-child(1)) {
            &::before {
                content: '';
                position: absolute;
                height: calc(100% - 20px);
                width: 1px;
                background: #999;
                top: 10px;
                left: 0;
            }
        }

        .text-12 {
            font-size: 12px;
        }

        .light-grey {
            color: #999;
        }

        .chat {
            position: relative;
            margin-right: 10px;

            img {
                width: 16px;
            }

            .count {
                position: absolute;
                background: #333;
                color: #fff;
                font-size: 8px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                text-align: center;
                line-height: 13px;
                right: -8px;
                top: -4px;
            }
        }
    }

    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
}
button.btn-border {
  border: 1px solid #555;
  border-radius: 0;
  margin-bottom: '10px';
  font-size: '14px';
  width: calc(50% - 20px);
  margin-bottom: 10px;
}
.text-underline {
  text-decoration: underline;
  width: 80px;
  text-align: right;
  margin: 10px 0;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}