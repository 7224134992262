.customer-sidebar {
    width: 15.88vw;
    height: 100%;
    position: fixed;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
    flex-direction: column;

    @media only screen and (orientation: portrait) {
        width: 50vw;
        z-index: 100;
        height: 12vw;
        overflow: hidden;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        width: 50vw;
        z-index: 100;
        height: 12vw;
        overflow: hidden;
    }

    .brand-logo {
        position: relative;
        height: 10.2vw;
        width: 100%;
        padding: 0.6vw;
        display: flex;
        align-items: center;
        justify-content: center;

        .fa-logo {
            width: 10vw;
            max-width: 100%;
        }
    }

    .sidebar-menu {
        //margin-top: 60px;
        padding: 0;

        .menu-item {
            padding: 0;
            margin-bottom: 1px;

            .item-button {
                background-color: transparent;
                height: 2.6vw;
                width: 100%;
                color: #fff;
                align-items: center;
                justify-content: start;
                text-transform: capitalize;
                font-size: 0.7vw;
                //padding: 25px;
                border-radius: 0;
                font-weight: normal;
                transition: all .3s ease-in-out;

                &.active, &:hover {
                    background-color: #233260;
                    //font-weight: bold;
                }
            }
        }
    }
}
