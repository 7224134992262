html {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}
body {
	height: 100%;
	margin: 0;
	font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&.dialog-open {
		overflow-y: hidden;
	}
}

input[disabled], input[readonly], select[disabled], select[readonly], div[aria-disabled] {
    background-color: #eef1f6;
}
