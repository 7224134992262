#root {
    height: 100%;
    width: 100%;

}
.padding_0 {
    padding: 0 !important;
    min-width: 47px !important;
}
.map-container{
    height: 100%;
    width: 100%;
}
.MuiTableCell-root {
    padding: 0 !important;
    padding-left: 3px !important;
    padding-top: 5px !important;
    text-align:  center !important;
}
th.MuiTableCell-root {
    padding-bottom: 3px !important;
}
*, body {
    box-sizing: border-box;

}

.header {
    .MuiInputBase-sizeSmall {
        max-height: 2.3vw !important;

        .MuiInputBase-input {
            font-size: 0.8vw !important;
        }
    }
}
.orderTable {
    tr:nth-child(even) {
        //background-color: #928d853b;
        background-color: #9ac3eb4a;
    }
}
.box_shadow_none{
    box-shadow:none !important;
}
.hover_none:hover {
    background: none !important;
}
.headerDate {
    .el-date-editor.el-input {
        width: 100px !important;
        font-size: 0.8vw !important;
    }

    .el-input__inner{
        height:2.4vw !important;
    }
}
.headerDate1 {
    

    .el-input__inner{
        height:2.4vw !important;
    }
}


@-webkit-keyframes error-stage-glowing {
    100% {
        background-color: #8000009e;
    }
}
@keyframes error-stage-glowing {
    100% {
        background-color: #8000009e;
    }
}
.locationTab {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 1vw !important;
    height: 2.2vw !important;
}
.locationTab:hover {
    background-color: #ddd;
}
.MuiButton-contained {
   // background-color: #1976d270 !important;
    color:black !important;
}
.MuiButton-containedSecondary {
    background-color: #bdbdbd69 !important;
    color: black !important;
}
.Mui-selected {
    // background:#ccc !important;
}
.MuiTabs-root{
    border:0px solid !important;
}
.search {
    .MuiInputLabel-root {
        transform: translate(14px, 3px) scale(1) !important;
    }

    .MuiInputLabel-animated {
        transform: translate(14px, -13px) scale(1) !important;
    }
}
.MuiInputLabel-root {
    transform: translate(14px, 12px) scale(1);
    &.MuiInputLabel-formControl {
        &.MuiInputLabel-animated.MuiFormLabel-filled {
            transform: translate(14px, 1) scale(0.75);
        }
    }
}