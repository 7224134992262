.customer-login-main-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url(/resources/loginBG.png);
    background-repeat: no-repeat;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        background-size: 110% 110%;
    }

    @media only screen and (orientation: portrait) {
        background-size: auto 290vw;
        background-position-x: -170vw;
    }

    .login-container {
        max-width: 100%;
        width: 30vw;
        height: 38vh;
        margin-top: 7vh;

        .login-title {
            color: #00a0d1;
            margin-top: 2vh;
            text-align: center;
            font-size: 1.3vw;
            font-weight: bold;

            div {
                display: inline-block;
            }
        }

        .login-title-strong {
            background-image: url(/resources/FALogo.png);
            background-repeat: no-repeat;
            background-size: 100%;
            height: 13vh;
            width: 17vw;
        }

        .login-title-strong-fa {
            background-image: url(/resources/faNewLogo.png);
            background-repeat: no-repeat;
            background-size: 100%;
            height: 10vh;
            width: 30vw;
        }

        .login-title-sub {
            // color:#00a0d1;
            color: #355394;
            font-weight: 400;
            -webkit-text-stroke: thin;
            margin-top: 2vh;
            margin-bottom: 2vh;
        }

        .login_form {
            padding: 0px 2vw 1vh;

            .login-input {
                text-indent: 1vh;
                height: 5vh;
                width: 100%;
                margin-top: 0.5vh;
                border: 1px solid #355394;
                box-shadow: grey 1px 0px 3px 0px;
                border-radius: 8px;
                font-size: 0.8vw;

                &:focus {
                    outline: none;
                }
            }

            .login-label {
                opacity: 0.8;
                //font-weight: bold;
                -webkit-text-stroke: thin;
                color: #707070;
                font-size: 1vw;
                letter-spacing: 0.4px;

                &.password {
                    margin-top: 1.1vw;
                }
            }

            .login-button {
                margin-top: 3.5vh;
                min-width: 25vh;
                width: 100%;
                height: 5vh;
                border-radius: 1vh;
                background: #192B45;
                color: #FFFFFF;
                font-family: Roboto;
                font-weight: 500;
                letter-spacing: 1.25px;
                text-align: center;
                border: 0px solid transparent;
                cursor: pointer;

                .btn-loading {
                    margin-left: 0.5vh;
                    position: relative;
                    top: 0.5vh;
                }

                .btn-label {
                    position: relative;
                    vertical-align: middle;
                    bottom: 1px;
                }
            }
        }

        .login-error {
            color: #f10a0a;
            position: relative;
            text-align: center;
            font-weight: 400;
        }
    }

    .login-container-m {

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            max-width: 100%;
            width: 45vw;
            height: 100vh;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                margin-top: 2vh;
            }

            @media only screen and (orientation: portrait) {
                margin-top: 7vh;
            }
        }

        @media only screen and (orientation: portrait) {
            max-width: 100%;
            width: 80vw;
            height: 100vh;
            margin-top: 7vh;
        }


        .login-title {
            color: #00a0d1;
            margin-top: 2vh;
            text-align: center;

            div {
                display: inline-block;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
            }

            @media only screen and (orientation: portrait) {
            }
        }

        .login-title-strong-fa {
            background-image: url(/resources/faNewLogo.png);
            background-repeat: no-repeat;
            background-size: 100%;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 10vh;
                width: 28vw;
            }

            @media only screen and (orientation: portrait) {
                height: 8vh;
                width: 68vw;
            }
        }

        .login-title-strong {
            background-image: url(/resources/FALogo.png);
            background-repeat: no-repeat;
            background-size: 100%;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 23vh;
                width: 28vw;
            }

            @media only screen and (orientation: portrait) {
                height: 30vw;
                width: 60vw;
            }
        }

        .login-title-sub-cms {
            // color:#00a0d1;
            color: #00AEEF !important;
            font-weight: 400;
            -webkit-text-stroke: thin;
            color: #355394;
            margin-bottom: 2vh;
            margin-top: 5.3vh;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                font-size: 1.8vw;
                margin-top: 0.3vh;
                margin-bottom: 1vh;
            }

            @media only screen and (orientation: portrait) {
                font-size: 3.5vw;
                margin-top: 0.3vh;
            }
        }

        .login-title-sub {
            // color:#00a0d1;
            color: #6b7375;
            font-weight: 400;
            -webkit-text-stroke: thin;
            color: #355394;
            margin-bottom: 2vh;
            margin-top: 5.3vh;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                font-size: 1.8vw;
                margin-top: 0.3vh;
            }

            @media only screen and (orientation: portrait) {
                font-size: 3.7vw;
                margin-top: 0.3vh;
            }
        }

        .login_form {
            padding: 0px 2vw 1vh;

            .login-input {
                text-indent: 1vh;
                width: 100%;
                margin-top: 0.5vh;
                border: 1px solid #355394;
                box-shadow: grey 1px 0px 3px 0px;
                border-radius: 8px;

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    font-size: 1.8vw;
                    height: 10vh;
                }

                @media only screen and (orientation: portrait) {
                    font-size: 4vw;
                    height: 5vh;
                }

                &:focus {
                    outline: none;
                }
            }

            .login-label {
                opacity: 0.8;
                //font-weight: bold;
                -webkit-text-stroke: thin;
                color: #707070;
                letter-spacing: 0.4px;
                width: 100%;
                text-align: center;
                margin-top: 8vh;
                margin-bottom: 5px;

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    font-size: 1.8vw;
                    margin-top: 1vh;
                    margin-bottom: 5px;
                }

                @media only screen and (orientation: portrait) {
                    font-size: 3vw;
                    margin-top: 8vh;
                    margin-bottom: 5px;
                }

                &.password {
                    margin-top: 3vh;

                    @media only screen and (orientation: landscape) and (max-width: 992px) {
                        margin-top: 1vh;
                    }

                    @media only screen and (orientation: portrait) {
                        margin-top: 3vh;
                    }
                }
            }

            .login-button {
                margin-top: 3.5vh;
                min-width: 25vh;
                width: 100%;
                border-radius: 1vh;
                background: #192B45;
                color: #FFFFFF;
                font-family: Roboto;
                font-weight: 500;
                letter-spacing: 1.25px;
                text-align: center;
                border: 0px solid transparent;
                cursor: pointer;

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    font-size: 1.8vw;
                    height: 10vh;
                    margin-top: 2vh;
                }

                @media only screen and (orientation: portrait) {
                    font-size: 3.5vw;
                    height: 5vh;
                }

                .btn-loading {
                    margin-left: 0.5vh;
                    position: relative;
                    top: 0.5vh;
                }

                .btn-label {
                    position: relative;
                    vertical-align: middle;
                    bottom: 1px;
                }
            }
        }

        .login-error {
            color: #f10a0a;
            position: relative;
            text-align: center;
            font-weight: 400;
        }
    }
}
