.customer-header {
    width: 100%;
    max-width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    padding-right: 30px;
    //margin-bottom: 24px;
    position: sticky;
    top: 0;
    z-index: 99;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        padding: 0;
        background: #707070;
        background: linear-gradient(180deg, rgba(53,83,148,1) 0%, rgba(6,16,15,1) 100%);
        height: 13vh;
    }

    @media only screen and (orientation: portrait) {
        padding: 0;
        background: #707070;
        background: linear-gradient(180deg, rgba(53,83,148,1) 0%, rgba(6,16,15,1) 100%);
        height: 7vh;
    }

    .MuiOutlinedInput-notchedOutline, .MuiInputLabel-root {
        border: none;
        border-bottom: none;
        border-radius: 0;
        color: #1976d2;
        font-family: "good-times",sans-serif !important;
        font-size: 0.8vw;

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            color: lightgrey;
        }

        @media only screen and (orientation: portrait) {
            color: lightgrey;
        }
    }

    .MuiInputBase-root {
        height: 2vw;

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            height: 13vh;
        }

        @media only screen and (orientation: portrait) {
            height: 2vh;
        }

        input {
            height: 2vw;

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 10vh;
            }

            @media only screen and (orientation: portrait) {
                height: 2vh;
            }
        }
    }

    .search-box {
        width: 100%;
        max-width: 345px;
        height: 38px;
        background-color: white;
        position: relative;

        input[type="search"] {
            border: none;
            height: 100%;
            padding: 0;
            width: 100%;
            padding-right: 38px;
            padding-left: 5px;
            outline: none;
        }

        .search-icon {
            padding: 0;
            position: absolute;
            right: 0;
            min-width: 38px;
            height: 38px;
            border: none;
            outline: none;
            border-radius: 0;
            background-color: transparent;

            img {
                width: 16px;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }

    .customer-details {
        .details-list {
            display: flex;
            padding: 0;

            .list-item {
                padding: 0 12px 0 10px;
                width: auto;
                border-left: 1px solid #898989;

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                }

                @media only screen and (orientation: portrait) {
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                .detail-icon {
                    padding: 0;
                    min-width: 28px;
                    border: none;
                    outline: none;
                    border-radius: 0;
                    background-color: transparent;
                    display: flex;
                    position: relative;

                    &:hover {
                        background-color: transparent;
                    }

                    .chat {
                        width: 24px;
                    }

                    .noti {
                        width: 13px;
                    }

                    .user {
                        width: 22px;
                    }

                    .chat-count {
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        font-size: 10px;
                        background-color: #333;
                        color: #fff;
                        border-radius: 50%;
                        top: -6px;
                        right: -6px;
                    }

                    .user-name {
                        font-size: 16px;
                        text-transform: capitalize;
                        padding-left: 5px;
                        color: #333;
                    }
                }
            }
        }
    }
}

.headerSearchIcon {
    width: 5vw;
    height: auto;
    color: lightgrey;

    @media only screen and (orientation: portrait) {
        width: 5vw;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        width: 5vw;
    }
}

.searchTextField {
    width: calc(100% - 1.84vw);
    border: none;
    height: 2vw;
    padding: 0;
    margin: 0;

    @media only screen and (orientation: portrait) {
        color: #fff !important;
        font-size: 4vw !important;
        height: 5vh;
        margin-bottom: 2vw !important;
        border-bottom: 1px solid #707070 !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        color: #fff !important;
        font-size: 4vw !important;
        height: 10vh;
        margin-bottom: 2vw !important;
        border-bottom: 1px solid #707070 !important;
    }
}
#dropdowm-menu {
    .MuiMenu-paper {
        min-width: 120px;
        box-shadow: 3px 3px 10px -1px #999;
        height: 4vh;
        overflow: hidden;

        .MuiList-root {
            @media only screen and (orientation: portrait) {
                height: 4vh;
                min-height: 1vh;
                max-height: 4vh;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 4vh;
                min-height: 1vh;
                max-height: 4vh;
            }

            li {
                @media only screen and (orientation: portrait) {
                    height: 4vh;
                    min-height: 1vh;
                    max-height: 4vh;
                }

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    height: 4vh;
                    min-height: 1vh;
                    max-height: 4vh;
                }
            }
        }
    }

    .MuiMenu-list {
        background: white;
        font-size: 3vh;
        padding: 0px;
    }

    .MuiMenuItem-gutters {
        &:hover {
            background-color: #fff;
        }
    }
}

.menuItem {
    font-size: 0.9vw !important;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 3vw !important;
    }
}

.submenuWindow {
    position: absolute;
    height: 10vh;
    width: 40vw !important;
    top: 7vh;
    left: 0;
    background: #fff;

    .icon-btn-header-cust {
        span {
            @media only screen and (orientation: portrait) {
                font-size: 3vw !important;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                font-size: 3vw !important;
            }
        }
    }

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 3vw !important;
    }
}