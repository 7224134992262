$primaryColor: #707070;
$secondaryColor: #7f7f7f;
$borderColor: #707070;
$white: #fff;

.pr-10 {
    padding-right: 10px !important;
}
.mr-5 {
    margin-right: 5px;
}

table.shipment-table {
    border-collapse: separate;
    //border-spacing: 0 1em;
    table-layout: unset;

    .shipment-row {
        background: transparent;

        td {
            padding: 1vw !important;
            border-top: 1px solid #3B3A3A45;
            border-bottom: 1px solid #3B3A3A45;
            text-align: center;
            /*&:first-child {
                border-left: 1px solid $borderColor;
            }
            &:last-child {
                border-right: 1px solid $borderColor;
            }*/
            &:first-child {
                padding-right: 0 !important;
            }

            &:last-child {
                padding-left: 0 !important;
            }
        }

        &.bookmark {
            td {
                position: relative;
                padding: 10px 5px 5px;
            }
        }
    }
}

.dataBlock {

    @media only screen and (orientation: portrait) {
        max-width: 100%;
        width: 100%;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        max-width: 45%;
        width: 45%;
    }
}

.shipment-no {
    display: flex;
    align-items: flex-start;
    margin-top: 0.5vw;
    font-size: 0.8vw;
    font-weight: 600;
    line-height: 1vw;
    color: $primaryColor;

    @media only screen and (orientation: portrait) {
        line-height: 5vw;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        line-height: 5vw;
    }

    img {
        width: 1.6vw;
        margin-right: 0.5vw;

        @media only screen and (orientation: portrait) {
            width: 7vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            width: 7vw;
        }
    }

    @media only screen and (orientation: portrait) {
        max-width: 100%;
        width: 100%;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        max-width: 100%;
        width: 100%;
    }
}
.actionButtons {
    border: 1px solid #707070;
    border-radius: 4px;
    height: 1.2vw;
    font-size: 0.6vw;
    margin-right: 0.5vw !important;
    margin-left: 0.5vw !important;

    @media only screen and (orientation: portrait) {
        margin: 2vw;
        margin-right: 1vw;
        margin-left: 1vw;
        width: 11vw;
        height: 4.5vw;
        cursor: pointer;
        font-size: 2.5vw;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        margin: 2vw;
        margin-right: 1vw;
        margin-left: 1vw;
        width: 11vw;
        height: 4.5vw;
        cursor: pointer;
        font-size: 2.5vw;
    }

    img {
        margin: 0.2vw;
        margin-bottom: 0.4vw;
        margin-top: 0.2vw;
        height: 0.7vw;

        @media only screen and (orientation: portrait) {
            margin-right: 1vw;
            margin-left: 1vw;
            height: 2.5vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            margin-right: 1vw;
            margin-left: 1vw;
            height: 2.5vw;
        }
    }

    span {
    }
}
.ship-trans {
    display: flex;
    border-left: 1px solid #3B3A3A45;
    border-right: 1px solid #3B3A3A45;
    padding-left: 5px;
    flex-direction: column;
    //max-width: 240px;
    color: $primaryColor;
    font-size: 15px;

    > div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        & + div {
            margin-top: 6px;
        }
    }

    .to {
        padding-left: 32px;
        position: relative;

        &::before {
            content: '';
            background-image: url('../../../../resources/right_icon.png');
            width: 26px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 3px;
            background-size: 30px;
            background-position-y: -10px;
            background-position-x: 0;
            opacity: .8;
        }
    }
}
.progress-bar {
    display: flex;
    margin: 0 -5px;

    .progress-step {
        position: relative;
        width: 7vw;
        margin-left: 10px;

        &::before, &:after {
            content: '';
            width: 50%;
            height: 2px;
            position: absolute;
            top: 9px;
        }



        &:first-child {
            &:before {
                content: none;
            }
        }

        &:last-child {
            &:after {
                content: none;
            }
        }

        .progress-status {
            width: 20px;
            height: 20px;
            border: 3px solid #0c65c1;
            display: inline-block;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            top: 0;
            left: calc(50% - 10px);
            z-index: 1;
        }

        .progress-text {
            font-size: 0.7vw;
            white-space: nowrap;
            line-height: 20px;
            //color: $primaryColor;
            text-align: left;
            font-weight: bold;
            color: #707070;
        }

        .prog-dec {
            font-size: 0.75vw;
            text-align: center;
            line-height: 20px;
            //color: $primaryColor;
            text-transform: capitalize;
            text-align: left;
            color: #707070;
        }

        &.done {
            .progress-status {

                &::before, &:after {
                    content: '';
                    width: 50%;
                    height: 2px;
                    position: absolute;
                    top: 9px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: -4px;
                    top: 5px;
                    height: 7px;
                    width: 3px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: -4px;
                    bottom: 2px;
                    height: 3px;
                    width: 10px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }
            }
        }

        &.inprogress {
            .progress-status {
                background-color: #0c65c1;
                border-color: #0c65c1;
                box-shadow: 1px 1px 3px 1px #ccc;
            }

            .progress-text {
                font-weight: bold;
            }
        }

        &.failed {
            .progress-status {
                background-color: red;
                border-color: red;
                box-shadow: 1px 1px 3px 1px #ccc;
            }


            .progress-text {
                font-weight: 500;
            }
        }
    }
}
button.detail-icon {
    padding: 0;
    min-width: 28px;
    border: none;
    outline: none;
    border-radius: 0;
    background-color: transparent;
    display: flex;
    position: relative;
    &:hover {
        background-color: transparent;
    }
    .noti {
        width: 20px;
    }
    .chat-count {
        position: absolute;
        width: 16px;
        height: 16px;
        font-size: 8px;
        line-height: 17px;
        background-color: #333;
        color: $white;
        border-radius: 50%;
        top: -6px;
        right: -6px;
    }
}
.shipment-action {
    display: flex;
    flex-direction: row-reverse;
    white-space: nowrap;

    .btn-primary {
        & + .btn-primary {
            margin-top: 5px;
        }
    }
}
button.btn-primary {
    min-width: auto;
    height: 4vh;
    background: inherit;
    background-color: $white;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #0c65c1;
    text-align: center;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    font-size: 14px;

    &.btn-filled {
        background-color: #0c65c1;
        color: $white;

        &:hover {
            background-color: #0c65c1;
        }
    }

    &:hover {
        background-color: $white;
        -moz-box-shadow: 1px 1px 3px 1px #ccc;
        -webkit-box-shadow: 1px 1px 3px 1px #ccc;
        box-shadow: 1px 1px 3px 1px #ccc;
    }
}