$primaryColor: #333;
$secondaryColor: #7f7f7f;
$borderColor: #797979;
$white: #fff;

.order-details {
    position: fixed;
    top: 0;
    left: 100%;
    background: rgb(47 79 79 / 60%);
    width: 100%;
    height: 100%;
    z-index: 99;
    transition: .3s all ease-in-out;
    visibility: hidden;

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        z-index: 102;
        background: transparent;
    }

    @media only screen and (orientation: portrait) {
        z-index: 102;
        background: transparent;
    }

    &.open {
        visibility: visible;
        left: 0;
    }

    .detail-wrapper {
        background: #fff;
        position: fixed;
        width: 70vw;
        height: 100vh;
        left: 16vw;
        top: 0;
        overflow-y: auto;
        overflow: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        @media only screen and (orientation: portrait) {
            width: 100%;
            left: 0;
            height: 93vh;
            top: 7vh;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            width: 100%;
            left: 0;
            height: 87vh;
            top: 13vh;
        }

        .order-tab-content {
            width: 34vw;
            max-width: 100vw;
            overflow-y: auto;

            @media only screen and (orientation: portrait) {
                height: 81vh;
                overflow-y: auto;
                width: 100vw;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 63vh;
                overflow-y: auto;
                width: 100vw;
            }

            input {
                padding: 0;
                height: 2.1vw;

                @media only screen and (orientation: portrait) {
                    height: 4vw;
                    margin-top: 2vw !important;
                }

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    height: 4vw;
                    margin-top: 2vw !important;
                }
            }
        }

        .order-tab-content-map {
            width: 34vw;
            max-width: 100vw;
            overflow-y: auto;
            height: calc(66vh - 160px);

            @media only screen and (orientation: portrait) {
                height: 48vh;
                overflow-y: auto;
                width: 100vw;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 30vh;
                overflow-y: auto;
                width: 100vw;
            }

            input {
                padding: 0;
                height: 2.1vw;

                @media only screen and (orientation: portrait) {
                    height: 4vw;
                    margin-top: 2vw !important;
                }

                @media only screen and (orientation: landscape) and (max-width: 992px) {
                    height: 4vw;
                    margin-top: 2vw !important;
                }
            }
        }


        .close {
            padding: 5px;
            min-width: auto;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            position: fixed;
            right: 16vw;
            top: 15px;
            z-index: 99;
            color: black;
            border: 1px grey solid;

            @media only screen and (orientation: portrait) {
                right: 2vw;
                top: 8vh;
                width: 6vw;
                height: 6vw;
                font-size: 4vw;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                right: 2vw;
                top: 14vh;
                width: 3vw;
                height: 3vw;
                font-size: 2vw;
            }
        }

        .close:hover {
            padding: 5px;
            min-width: auto;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            position: fixed;
            right: 16vw;
            top: 15px;
            z-index: 99;
            border: 1px #00AEEF solid;
            color: #00AEEF !important;

            @media only screen and (orientation: portrait) {
                right: 2vw;
                top: 8vh;
                width: 6vw;
                height: 6vw;
                font-size: 4vw;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                right: 2vw;
                top: 14vh;
                width: 3vw;
                height: 3vw;
                font-size: 2vw;
            }
        }

        .tabIcons {
            fill: grey;
        }

        .tabIcons:hover {
            fill: #00AEEF;
        }

        .map-section {
            height: 33vh;
            /*@media only screen and (orientation: portrait) {
                height: 0;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 0;
            }*/
        }


        .order-detail-section1 {
            width: 33vw;
            font-size: 0.8vw;

            @media only screen and (orientation: portrait) {
                margin-top: 5vh;
                width: 100vw;
                font-size: 2.8vw;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                margin-top: 8vh;
                width: 100vw;
                font-size: 2vw;
            }
        }

        .order-detail-section {
            //background: #fafafa;
            position: sticky;
            width: 100%;
            top: 0;
            z-index: 9;
            max-width: 100vw;

            @media only screen and (orientation: portrait) {
                height: 6vh;
            }

            @media only screen and (orientation: landscape) and (max-width: 992px) {
                height: 16vh;
            }

            .order-img {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;

                img {
                    width: 40px;
                    margin-right: 10px;
                }

                .order-no {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .order-transit {
                    display: flex;
                    font-size: 14px;

                    .from {
                    }

                    .to {
                        padding-left: 32px;
                        position: relative;

                        &::before {
                            content: '';
                            background-image: url('../../../../resources/right_icon.png');
                            width: 26px;
                            height: 10px;
                            position: absolute;
                            left: 0;
                            top: 3px;
                            background-size: 30px;
                            background-position-y: -10px;
                            background-position-x: 0;
                            opacity: .8;
                        }
                    }
                }
            }

            button.detail-tab {
                width: 25%;
                background: transparent;
                border: none;
                min-width: auto;
                border-radius: 0;
                transition: all .3s ease-in-out;
                height: auto;
                //padding: 3px 20px;
                margin-bottom: 10px;
                position: relative;
                font-weight: normal;

                .mail-icon {
                    font-size: 14px;
                    margin-right: 5px;
                }

                &:not(:last-child) {
                    border-right: 1px solid #aaa;
                }

                &.active, &:hover {
                    box-shadow: none;

                    &:not(:hover) {
                        font-weight: 600;
                    }

                    &::before {
                        transition: all .3s ease-in-out;
                        content: '';
                        //border-bottom: 1px solid #666;
                        position: absolute;
                        width: 90%;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
.order-tab-content {

    .requested-actions {
        padding: 1vw 3vw;
        border-bottom: 1px solid $borderColor;
    }

    .title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;

        span {
            font-size: 14px;
            font-weight: normal;
            color: $secondaryColor;
        }
    }

    .descrition {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .descrition-1 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .descrition-2 {
        font-size: 14px;
        color: $secondaryColor;
        margin-bottom: 10px;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-primary {
            font-size: 14px;
            font-weight: normal;
            width: auto;
        }
    }

    .shipment-status {
        padding: 1.5vw 4vw;
    }
}

.status-wrapper {
    .status-step {
        display: flex;
        padding-bottom: 20px;

        .status {
            width: 20px;
            position: relative;
            margin-right: 30px;

            &::before {
                content: '';
                position: absolute;
                height: 100%;
                border-right: 2px dashed #0c65c1;
                top: 20px;
                left: 9px;
            }

            &::after {
                content: '';
            }

            .status-circle {
                width: 20px;
                height: 20px;
                border: 1px solid #0c65c1;
                display: inline-block;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: calc(50% - 10px);
                z-index: 1;
            }
        }

        &:last-child {
            .status {
                &::before {
                    content: none;
                }
            }
        }

        &.done {
            .status {
                &::before {
                    border-right: 2px solid #0c65c1;
                }

                &::after {
                    content: '';
                }
            }

            .status-circle {
                border: 3px solid #0c65c1;

                &::before {
                    content: "";
                    position: absolute;
                    left: -4px;
                    top: 5px;
                    height: 7px;
                    width: 3px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: -4px;
                    bottom: 2px;
                    height: 3px;
                    width: 10px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }
            }
        }

        &.inprogress {
            .status {
                &::before {
                    content: '';
                }

                &::after {
                    content: '';
                }
            }

            .status-circle {
                background-color: #0c65c1;
                border-color: #0c65c1;
                box-shadow: 1px 1px 3px 1px #ccc;
            }

            .status-title {
                font-size: 15px;
            }
        }

        &.failed {
            .status-circle {
                background-color: red;
                border-color: red;
                box-shadow: 1px 1px 3px 1px #ccc;
            }
        }

        .status-row {
            display: flex;
            width: 100%;

            .status-col-1 {
                width: 100%;
            }
        }

        .status-title {
            font-size: 14px;
        }

        .desc-line-1, .desc-line-2 {
            font-size: 14px;
            color: $secondaryColor;
        }

        .status-col-2 {
            display: flex;

            .time-icon {
                font-size: 12px;
                background: #000;
                color: #fff;
                border-radius: 50%;
                padding: 1px;
                margin-right: 5px;
                margin-top: 1px;
            }

            p {
                font-size: 14px;
                color: $secondaryColor;
            }
        }
    }

    .status-step1 {
        display: flex;
        padding-bottom: 20px;

        .status {
            width: 20px;
            position: relative;
            margin-right: 30px;

            &::before {
                content: '';
                position: absolute;
                height: 100%;
                //border-right: 2px dashed #0c65c1;
                top: 20px;
                left: 9px;
            }

            &::after {
                content: '';
            }

            .status-circle {
                width: 20px;
                height: 20px;
                border: 1px solid #0c65c1;
                display: inline-block;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: calc(50% - 10px);
                z-index: 1;
            }
        }

        &:last-child {
            .status {
                &::before {
                    content: none;
                }
            }
        }

        &.done {
            .status {
                &::before {
                    border-right: 2px solid #0c65c1;
                }

                &::after {
                    content: '';
                }
            }

            .status-circle {
                border: 3px solid #0c65c1;

                &::before {
                    content: "";
                    position: absolute;
                    left: -4px;
                    top: 5px;
                    height: 7px;
                    width: 3px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: -4px;
                    bottom: 2px;
                    height: 3px;
                    width: 10px;
                    background-color: #0c65c1;
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                }
            }
        }

        &.inprogress {
            .status {
                &::before {
                    content: '';
                }

                &::after {
                    content: '';
                }
            }

            .status-circle {
                background-color: #0c65c1;
                border-color: #0c65c1;
                box-shadow: 1px 1px 3px 1px #ccc;
            }

            .status-title {
                font-size: 16px;
            }
        }

        &.failed {
            .status-circle {
                background-color: red;
                border-color: red;
                box-shadow: 1px 1px 3px 1px #ccc;
            }
        }

        .status-row {
            display: flex;
            width: 100%;

            .status-col-1 {
                width: 60%;
            }
        }

        .status-title {
            font-size: 15px;
        }

        .desc-line-1, .desc-line-2 {
            font-size: 14px;
            color: $secondaryColor;
        }

        .status-col-2 {
            display: flex;

            .time-icon {
                font-size: 12px;
                background: #000;
                color: #fff;
                border-radius: 50%;
                padding: 1px;
                margin-right: 5px;
                margin-top: 1px;
            }

            p {
                font-size: 14px;
                color: $secondaryColor;
            }
        }
    }
}
.documents {
    padding: 1.5vw 2vw;

    @media only screen and (orientation: portrait) {
        padding: 2.5vw 5vw;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        padding: 2.5vw 5vw;
    }

    .drag-drop-zone {
        width: 100%;
        height: 18vh;
        border-radius: 5px;
        border: 1px solid #00AEEF;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-top: 10px;
        color: #707070;
        font-size: 0.7vw;

        @media only screen and (orientation: portrait) {
            font-size: 2.7vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            font-size: 2.7vw;
            height: 30vh;
        }

        img {
            width: 45px;
            margin-bottom: 0.2vw;
        }

        p {
            font-size: 14px;
        }

        .upload-btn {
            border: 1px solid #333;
            font-size: 15px;
            padding: 3px 15px;
            border-radius: 3px;

            input {
                display: none;
            }
        }
    }

    .docs-accordion {
        margin-bottom: -1px;

        &.Mui-expanded {
            margin: 0;
            margin-bottom: -1px;
        }

        .docs-content {
            padding: 10px;

            p {
                font-size: 14px;
            }
        }
    }

    .MuiAccordionSummary-gutters {
        border: 1px solid #333;
        padding: 0;
        min-height: auto;

        &.Mui-expanded {
            min-height: auto;
            margin: 0;
            background: #d7d7d7;
            border-bottom: 0;
        }

        .MuiAccordionSummary-contentGutters {
            margin: 0;
        }
    }

    .MuiCollapse-wrapper {
        border: 1px solid #333;
    }

    .docs-list {
        padding: 3px 10px;
        display: flex;

        .docs-count {
            font-size: 16px;

            &.docs-missing {
                font-weight: 600;
                margin-left: 10px;
            }
        }
    }

    .table-grid {
        display: flex;
        padding: 5px 10px;
        width: 100%;

        &.top-bar {
            padding-right: 35px;
        }

        p {
            font-size: 0.8vw;

            &:nth-child(1) {
                width: 50%;
            }

            &:nth-child(2) {
                width: 35%;
            }

            &:nth-child(3) {
                width: 25%;
            }
        }
    }
}
.tab-wrapper {
    margin-top: 10px;
    border-bottom: 1px solid #707070;

    @media only screen and (orientation: portrait) {
        border-top: 1px solid #707070;
        border-bottom: none;
        width: 90vw !important;
        margin-left: 5vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        border-top: 1px solid #707070;
        border-bottom: none;
        width: 90vw !important;
        margin-left: 5vw !important;
    }
}
.fileDownloadLink {
    margin-bottom: 5px;
    font-size: 0.8vw;

    @media only screen and (orientation: portrait) {
        font-size: 2.4vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 2.4vw !important;
    }
}
.detailsFileUploadTabs {
    color: #707070;
    font-size: 0.7vw;

    @media only screen and (orientation: portrait) {
        font-size: 2.7vw !important;
        margin-top: 2vw !important;
        margin-bottom: 2vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 2.7vw !important;
        margin-top: 2vw !important;
        margin-bottom: 2vw !important;
    }
}
.tabButtons {
    font-size: 0.8vw !important;

    @media only screen and (orientation: portrait) {
        font-size: 2vw !important;
        padding: 0 !important;
        margin-top: 2vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 1.8vw !important;
        padding: 0 !important;
        margin-top: 2vw !important;
    }

    img {
        height: 1.1vw;

        @media only screen and (orientation: portrait) {
            height: 3vw;
            margin-right: 0.5vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            height: 3vw;
            margin-right: 0.5vw;
        }
    }
}

.MuiInputBase-input, .MuiInputBase-root {
    font-size: 0.8vw !important;

    @media only screen and (orientation: portrait) {
        color: #fff !important;
        font-size: 4vw !important;
        margin-top: 4vw;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        color: #fff !important;
        font-size: 2vw !important;
    }
}

.MuiInputLabel-root {
    font-size: 0.8vw !important;

    @media only screen and (orientation: portrait) {
        font-size: 2vw !important;
        margin-top: 2vh;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 1vw !important;
        margin-top: 2vw;
    }
}

.label{
    padding:0;
}
.chatBoddyText {
    font-size: 0.8vw;
    margin: 10px;
    height: 25vh;
    overflow-y: auto;
    border: 1px solid #00AEEF;
    display: flex;
    flex-direction: column-reverse;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 2vw !important;
        height: 40vh !important;
    }
}
.chatBodyTextBox {
    font-size: 0.8vw;
    width: 100vw !important;
    height: 2.1vw;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
        margin-left: 3vw !important;
        width: 90% !important;
    }

    //@media only screen and (orientation: landscape) and (max-width: 992px) {
    @media only screen and (orientation: landscape) {
        font-size: 3vw !important;
        margin-left: 0 !important;
        width: 100% !important;
    }

    .MuiInputBase-input, .MuiInputBase-root {
        font-size: 0.8vw !important;
        color: #00AEEF !important;

        @media only screen and (orientation: portrait) {
            color: #00AEEF !important;
            font-size: 3vw !important;
            margin-top: 4vw !important;
            margin-left: 2vw !important;
            margin-bottom: 2vw !important;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            color: #00AEEF !important;
            font-size: 3vw !important;
            margin-top: 4vw !important;
            margin-left: 2vw !important;
            margin-bottom: 2vw !important;
        }
    }

    .MuiInputLabel-root {
        font-size: 0.8vw !important;

        @media only screen and (orientation: portrait) {
            font-size: 3vw !important;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            font-size: 3vw !important;
        }
    }
}

.chatSendButton {
    font-size: 0.8vw !important;
    background: #00AEEF;
    border: #707070;
    border-radius: 4px;
    color: #FFF;
    width: 4.8vw;
    height: 2.1vw;

    @media only screen and (orientation: portrait) {
        margin-top: 4vw;
        padding: 0;
        height: 8vw;
        width: 13vw;
        font-size: 3vw !important;
        margin-left: 3vw !important;
        margin-right: 3vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        margin-top: 4vw;
        padding: 0;
        height: 8vw;
        width: 13vw;
        font-size: 3vw !important;
        margin-left: 3vw !important;
        margin-right: 3vw !important;
    }
}