.shipment-map-details {
    margin-top: 24px;
    padding-bottom: 24px;
    overflow: hidden;
    .map-container {
        position: relative;
        background-color: #ccc;
        min-height: 100px;
        > div:not(.order-slide-details) {
            min-height: 480px; 
            > div {
                min-height: 100%;
            }
        }
        .list-view {
            background-color: #f2f2f2;
            border: none;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: absolute;
            right: 0;
            top:0;
            line-height: 16px;
            img {
                width: 16px;
                margin-right: 5px;
            }
        }
    }
}
.shipment-slider {
    margin-top: 24px; 
    transition: all .3s ease-in-out;
    transition-delay: .2s;
    &.hidden {
        visibility: hidden;
    }
    .swiper {
        padding: 0 40px;
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            background: #fff;
            height: 100%;
            width: 40px;
            z-index: 9;
            left: 0;
            top: 0;
        }
        &::after {
            left: auto;
            right: 0;
        }
    }
    .swiper-button-next, .swiper-button-prev {
        color: #333;
        &::after {
            font-size: 26px;
            font-weight: bold;
        }
    }
    .swiper-button-prev {
        margin-left: -5px;
    }
    .swiper-button-next {
        margin-right: -5px;
    }
}
.shipment-slide {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 16px 8px 36px;
    position: relative;
    .shipment-img {
        img {
            width: 30px;
        }
    }
    .shipment-details {
        width: 100%;
        padding: 0 1.5vw;
        .shipment-no {
            margin-top: -5px;
            margin-bottom: 5px;
        }
        .source-destination, .status-date {
            font-size: 15px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            > div + div {
                margin-left: 20px;
            }
            .to {
                padding-left: 32px;
                position: relative;
                &::before {
                    content: '';
                    background-image: url('../../../../resources/right_icon.png');
                    width: 26px;
                    height: 10px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                    background-size: 30px;
                    background-position-y: -10px;
                    background-position-x: 0;
                    opacity: .8;
                }

            }
            .status {
                color: #7f7f7f;
            }
        }
    }
    .shipment-actions {
        .target-action {
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            width: 20px;
            min-width: auto;
            img {
                max-width: 100%;
            }
        }
        .btn-more {
            position: absolute;
            font-size: 14px;
            right: 6px;
            bottom: 6px;
            padding: 0;
            height: 24px;
            width: 86px;
            border-radius: 0;
            padding-top: 1px;
        }
    }
}
.order-slide-details {
    position: absolute;
    visibility: hidden;
    height: calc(100% - 40px);
    width: 336px;
    transition: all .5s ease-in-out;
    background: #f2f2f2;
    left: 40px;
    bottom: -100%;
    &.open {
        visibility: visible;
        bottom: 20px;
    }
    button.target-close {
        background: #333;
        min-width: auto;
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 5px;
        border-radius: 50%;
        z-index: 3;
        &:hover {
            opacity: .9;
        }
        img {
            width: 100%;
            width: 12px;
        }
    }
    .order-slide-progress {
        max-height: 100%;
        overflow-y: auto;
        .shipment-slide {
            padding: 16px 16px 0;
            position: sticky;
            top: 0;
            background: #f2f2f2;
            z-index: 2;
        }
        .status-wrapper {
            padding: 20px 0 0 60px;
            .status-step {
                &:not(:last-child) {
                    min-height: 40px;
                }
                .status {
                    margin-right: 20px;
                    &::before {
                        left: 8px;
                    }
                }
            }
        }
        .requested-actions {
            padding: 16px 32px;
            .title {
                font-size: 14px;
                font-weight: 600;
            }
            .description {
                font-size: 13px;
                margin: 5px 0;
            }
            .actions {
                display: flex;
                justify-content: space-between;
                button.btn-primary {
                    min-width: auto;
                }
            }
        }
    }
}