
@import url("https://p.typekit.net/p.css?s=1&k=fni3rqa&ht=tk&f=347.348.349.350.353&a=16162977&app=typekit&e=css");

@font-face {
    font-family: "good-times";
    src: url("https://use.typekit.net/af/9160e6/00000000000000007735968e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9160e6/00000000000000007735968e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9160e6/00000000000000007735968e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "good-times";
    src: url("https://use.typekit.net/af/3e86d3/00000000000000007735968f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3e86d3/00000000000000007735968f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3e86d3/00000000000000007735968f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "good-times";
    src: url("https://use.typekit.net/af/7d4e6e/000000000000000077359692/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/7d4e6e/000000000000000077359692/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/7d4e6e/000000000000000077359692/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "good-times";
    src: url("https://use.typekit.net/af/5debe0/000000000000000077359694/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/5debe0/000000000000000077359694/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/5debe0/000000000000000077359694/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "good-times-bad-times";
    src: url("https://use.typekit.net/af/777999/00000000000000007735967b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/777999/00000000000000007735967b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/777999/00000000000000007735967b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
}

.tk-good-times {
    font-family: "good-times",sans-serif !important;
}

.tk-good-times-bad-times {
    font-family: "good-times-bad-times",sans-serif !important;
}
.font-heebo {
    font-family: "Heebo",sans-serif !important;
}
.font-segoe {
    font-family: "Segoe UI",sans-serif !important;
}

.home{
    height: 100%;
    width: 100%;
}
.main-page{
    height: 100%;
    position: relative;
}
.grey_bg {
    .MuiTextField-root {
        background: #f1f1f10a !important;
    }
}
.chatSendButton{
    color: white;
}

.logged-in-user-container {
    position: absolute;
    right: 25px;
    top: 4px;
    cursor: pointer;

    .dropdown {
        padding: 10px 15px;
        font-weight: 200;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: 1px;
        color: #39393b;
        margin-left: 16px;
    }

    .dropdown .dropbtn {
        font-size: 16px;
        border: none;
        outline: none;
        color: #00a0d1;
        padding: 4px 10px;
        background-color: #FFFFFF;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 100px;
        right: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content span {
        float: none;
        padding: 8px 15px;
        display: block;
        cursor: pointer;
    }

    .dropdown-content span:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
}
