$primaryColor: #333;
$secondaryColor: #7f7f7f;
$borderColor: #797979;
$white: #fff;

.shipmentDropsoenSelect {
    color: #707070;
    -webkit-text-stroke: thin;
    font-size: 0.5vw;
    height: 3vh;
    width: 6vw;
    border-radius: 4px;
    box-shadow: grey 0px 0px 3px 0px;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
        height: 3.5vh !important;
        width: 30vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 3vw !important;
        height: 7vh !important;
        width: 22vw !important;
    }
}

.filterSection1 {
    max-width: 25vw;
}

.tab-wrapper1 {
    width: 100% !important;
}

.fa-logo {

    @media only screen and (orientation: portrait) {
        width: 70%;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        width: 50%;
    }
}

.customerShipmentFilter {
    margin-left: 5vw;
    display: flex;

    @media only screen and (orientation: portrait) {
        margin-left: 5vw !important;
        margin-top: 4vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        margin-left: 5vw !important;
        margin-top: 4vw !important;
    }
}

.typoTextBottom {
    font-size: 0.7vw;
    -webkit-text-stroke: thin;
    color: #707070;
    margin-bottom: 2vh;

    @media only screen and (orientation: portrait) {
        font-size: 2.5vw !important;
        margin-bottom: 1vh;
        position: relative;
        top: -2.6vh;
        left: 4vw;
        width: 0;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 1.5vw !important;
        margin-bottom: 1vh;
        position: relative;
        top: -8vh;
        left: 1vw;
        width: 0;
    }
}
.typoText {
    font-size: 0.7vw;
    -webkit-text-stroke: thin;
    color: #707070;
    margin-bottom: 2vh;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
        margin-bottom: 1vh;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 1.5vw !important;
        margin-bottom: 1vh;
    }
}

.menuItem1 {
    font-size: 0.5vw !important;
    height: 3vh;
    -webkit-text-stroke: thin;
    color: #707070;

    @media only screen and (orientation: portrait) {
        font-size: 3vw !important;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        font-size: 3vw !important;
    }
}
.shipment-list {
    height: calc(80vh);
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}
.MuiMenuItem-root.Mui-selected {
    color: #00AEEF;
    -webkit-text-stroke: thin;
    background: none;
}
.shipments-header {
    height: 10vh;

    @media only screen and (orientation: portrait) {
        height: 10vh;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        height: 13vh;
    }

    .tab-grid {
        display: flex;
        align-items: center;

        .tab-title {
            font-size: 16px;
            margin-right: 2vw;
            font-weight: 600;
        }
    }

    .filter-grid {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;

        > div {
            margin-bottom: 10px;

            &:not(.list-map-view) {
                margin-right: 15px;
            }
        }

        .sort-filter {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .title {
                font-size: 0.7vw;
                margin-bottom: 0.25vw;
                color: #707070;
                -webkit-text-stroke: thin;
            }

            .sort-select {
                outline: none;

                .MuiSelect-select {
                    display: flex;
                    align-items: center;
                    width: 140px;
                    height: 34px;
                    padding: 0;
                    font-size: 15px;
                    border-radius: 0;
                    border: 1px solid #0c65c1;
                    color: #0c65c1;
                    padding: 0 8px;
                    outline: none;

                    fieldset {
                        border: none;
                        outline: none;
                    }

                    .MuiMenuItem-gutters {
                        font-size: 16px;
                    }
                }
            }
        }

        .shipment-type-status-filter {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                font-size: 0.7vw;
                margin-bottom: 0.25vw;
                color: #707070;
                -webkit-text-stroke: thin;
            }

            .form-group {
                display: flex;
                flex-direction: row;
                align-items: center;

                .filter-label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 4vh;
                    min-width: 3vw;
                    background: inherit;
                    background-color: $white;
                    box-sizing: border-box;
                    border: 1px solid #00AEEF;
                    text-align: center;
                    border-radius: 0;
                    font-size: 0.7vw;
                    white-space: nowrap;
                    padding: 0 5px;
                    color: #00AEEF;

                    & + .filter-label {
                        margin-left: 5px;
                    }

                    &.active {
                        background-color: #00AEEF;
                        color: #fff;
                    }

                    img {
                        margin-bottom: 3px;
                        width: 16px;
                    }

                    input {
                        display: none;
                    }
                }
            }
        }
    }
}
.selectonBoxController {
    width: 40vw !important;

    .MuiInputLabel-root {
        font-size: 5vw !important;
        color: black;

        @media only screen and (orientation: portrait) {
            font-size: 2vw !important;
            margin-top: 5vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            font-size: 3vw !important;
            margin-top: 4vw;
        }
    }
}

.tab-wrapper {
    .btn-tab {
        font-size: 0.5vw;
        width: 5vw;
        border-radius: 4px;
        transition: all .3s ease-in-out;
        border: 1px solid #707070;
        box-shadow: grey 0px 0px 3px 0px;
        color: #707070;
        -webkit-text-stroke: thin;
        height: 3vh;

        @media only screen and (orientation: portrait) {
            font-size: 3vw !important;
            width: 15vw;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
            font-size: 3vw !important;
            width: 15vw;
        }


        &.active {
            background-color: #00AEEF;
            color: white;

            &::before {
                background: #00AEEF;
            }
        }

        & + .btn-tab {
            margin-left: 5px;
        }
    }
}

.search-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .search-box {
        max-width: 208px;
        display: flex;
        flex-direction: row;
        position: relative;
        border-bottom: 1px solid $borderColor;
        height: 36px;
        padding-right: 36px;
        input[type="search"] {
            outline: none;
            border: none;
            height: 35px;
        }
        button {
            background: transparent;
            padding: 0;
            outline: none;
            position: absolute;
            width: 36px;
            height: 36px;
            min-width: auto;
            right: 0;
            img {
                max-width: 24px;
            }
        }
    }
}
.list-map-view {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    button.view-label {
        height: 36px;
        width: 36px;
        min-width: auto;
        background: inherit;
        background-color: $white;
        box-sizing: border-box;
        border: 1px solid $borderColor;
        padding: 5px;
        border-radius: 0;
        & + .view-label {
            margin-left: 1px;
        }
        &.active {
            background-color: #7f7f7f;
        }
        img {
            max-width: 100%;
        }
    }
}

