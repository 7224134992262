.dropzone {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  background-color: #eee;
  border: 2px solid #eee;
  user-select: none;
}

.dashed {
  border: 2px dashed #52a2ec;
}
